import i18n from '@/assets/i18n/index.js'
import { format } from 'date-fns'
const { t } = i18n.global;
export const formatDateElapsedTime = (dateString) => {

    if (!dateString) {
        return '-'
    }

    const date = new Date(dateString);
    const now = new Date();

    const timeDifference = now - date;

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
        return seconds + t('date.seconds')
    } else if (minutes < 60) {
        return minutes + t('date.minutes')
    } else if (hours < 24) {
        return hours + t('date.hours')
    } else if (days < 30) {
        return days + t('date.days')
    } else if (months < 12) {
        return months + t('date.months')
    } else {
        return years + t('date.years')
    }
}

export const formatDate = (dateString) => {
    if (!dateString) {
        return '-'
    }
    const date = new Date(dateString);
    const year = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', year: 'numeric' });
    const month = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', month: '2-digit' });
    const day = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', day: '2-digit' });
    const hours = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', hour: '2-digit' });
    const minutes = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', minute: '2-digit' });
    const seconds = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', second: '2-digit' });

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
}

export const formatTooltipDate = (dateString, graph) => {
    const date = new Date(dateString);

    const year = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', year: 'numeric' });
    const month = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', month: '2-digit' });
    const day = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', day: '2-digit' });
    const hours = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', hour: '2-digit' });
    const minutes = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', minute: '2-digit' });
    const seconds = date.toLocaleString('en-US', { timeZone: 'Europe/Bucharest', second: '2-digit' });

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const formattedDate = `${year}-${month}-${day}`;
    return ['gf_market_price',
        'gf_market_capitalization',
        'gf_exchange_trade_volume',
        'gf_circulating_supply'].includes(graph) ? formattedDateTime : formattedDate;
}

export const formatBreadcrumbsDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "eee do MMM yyyy")
}

export const formatPriceDate = (dateString) => {
    if (dateString) {
        const date = new Date(dateString)
        return format(date, "dd MMM yyyy")
    }
    return "-"
}

export const formatForSearch = (date) => {
    return format(date, "dd/MM/yyyy")
}

export const formatOrderExpirationTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    return `${padTo2Digits(minutes)} min ${padTo2Digits(seconds)} s`;

}
